<template>
  <!-- 待办任务 -->
  <div style="position: relative;">
    <el-form ref="formMyTask" :model="formMyTask" label-width="80px" :size="defaultFormItemSize" label-position="right" @submit.native.prevent @keyup.enter.native="refreshMyTask(true)">
      <filter-box :item-width="350" @search="refreshMyTask(true)" @reset="onReset">
        <el-form-item label="流程名称" prop="formFilter.processDefinitionName">
          <el-input class="filter-item" v-model="formMyTask.formFilter.processDefinitionName"
            :clearable="true" placeholder="流程名称" />
        </el-form-item>
        <el-form-item label="发起人" prop="formFilter.startUser">
          <el-input class="filter-item" v-model="formMyTask.formFilter.startUser"
            :clearable="true" placeholder="发起人" />
        </el-form-item>
        <el-form-item label="注释1" prop="formFilter.keyOne">
          <el-input class="filter-item" v-model="formMyTask.formFilter.keyOne"
            :clearable="true" placeholder="注释1" />
        </el-form-item>
        <el-form-item label="标题" prop="formFilter.keyword1">
          <el-input class="filter-item" v-model="formMyTask.formFilter.taskName"
            :clearable="true" placeholder="标题" />
        </el-form-item>
      </filter-box>
    </el-form>
    <table-box ref="flowEntry" :data="formMyTask.taskWidget.dataList" :size="defaultFormItemSize" @sort-change="formMyTask.taskWidget.onSortChange"
      @refresh="refreshMyTask(true)" :seq-config="{startIndex: ((formMyTask.taskWidget.currentPage - 1) * formMyTask.taskWidget.pageSize)}" :hasExtend="false">
      <vxe-column title="序号" type="seq" width="55px" :index="formMyTask.taskWidget.getTableIndex" />
      <vxe-column title="流程名称" field="processDefinitionName" />
      <vxe-column title="工单编号" field="workOrderCode" />
      <vxe-column title="注释1" field="keyword1"/>
      <vxe-column title="注释2" field="keyword2"/>
      <vxe-column title="注释3" field="keyword3"/>
      <vxe-column title="当前任务" field="taskName" />
      <vxe-column title="发起人昵称" field="showName" />
      <vxe-column title="任务发起时间" field="processInstanceStartTime">
        <template slot-scope="scope">
          <Time-Format :time="scope.row.processInstanceStartTime" type="1" :key="scope.row.workOrderCode"></Time-Format>
        </template>
      </vxe-column>
      <vxe-column title="操作" width="150px">
        <template slot-scope="scope">
          <el-button :size="defaultFormItemSize" type="text" @click="onSubmit(scope.row)">办理</el-button>
          <el-button :size="defaultFormItemSize" type="text" @click="printIfCan(scope.row)">打印</el-button>
        </template>
      </vxe-column>
      <el-row slot="pagination" type="flex" justify="end" style="margin-top: 16px;">
        <el-pagination
          :total="formMyTask.taskWidget.totalCount"
          :current-page="formMyTask.taskWidget.currentPage"
          :page-size="formMyTask.taskWidget.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, prev, pager, next, sizes"
          @current-change="formMyTask.taskWidget.onCurrentPageChange"
          @size-change="formMyTask.taskWidget.onPageSizeChange">
        </el-pagination>
      </el-row>
    </table-box>
  </div>
</template>

<script>
import '@/staticDict/flowStaticDict.js';

/* eslint-disable-next-line */
import { FlowOperationController } from '@/api/FlowController';
/* eslint-disable-next-line */
import { cachePageMixin, statsDateRangeMixin, uploadMixin } from '@/core/mixins';
/* eslint-disable-next-line */
import { TableWidget } from '@/utils/widget.js';

import { fetchDownloadBlob } from '@/core/http';


export default {
  name: 'formMyTask',
  mixins: [uploadMixin, statsDateRangeMixin, cachePageMixin],
  data () {
    return {
      formMyTask: {
        formFilter: {
          processDefinitionName: undefined,
          processDefinitionKey: undefined,
          taskName: undefined,
          startUser: undefined,
          keyOne: undefined
        },
        formFilterCopy: {
          processDefinitionName: undefined,
          processDefinitionKey: undefined,
          taskName: undefined,
          startUser: undefined,
          keyOne: undefined
        },
        taskWidget: new TableWidget(this.loadTaskData, this.loadTaskDataVerify, true, false, 'entryId', 1),
        isInit: false
      }
    }
  },
  methods: {
    printIfCan ({processDefinitionKey, workOrderId, pageId}) {
      doUrl(`/admin/flow/flowOnlineOperation/printWorkOrderV2/${processDefinitionKey}`, 'POST', {
        pageId,
        printParams: [
          [
            {
              paramName: 'workOrderId',
              paramValue: workOrderId
            }
          ]
        ]
      }).then(res => {
        if (res.data) {
          const downloadUrl = res.data
          fetchDownloadBlob(downloadUrl, {}, 'get', 'application/pdf').then(blobData => {
            try {
              let pdfUrl = window.URL.createObjectURL(blobData);
              window.open(pdfUrl, '_blank');
              // 如果立刻销毁 会导致 Safir 浏览器弹出窗 打开之前就无法使用 因此临时解决办法是 延迟销毁
              setTimeout(() => {
                window.URL.revokeObjectURL(pdfUrl);
              }, 15000);
            } catch (error) {
              this.$message.error(error);
            }
          }).catch(e => {
            this.$message.error(e);
          });
        }
      })
    },
    onReset () {
      this.$refs.formMyTask.resetFields();
      this.refreshMyTask(true);
    },
    loadTaskData (params) {
      if (params == null) params = {};
      params = {
        processDefinitionName: this.formMyTask.formFilterCopy.processDefinitionName,
        processDefinitionKey: this.formMyTask.formFilterCopy.processDefinitionKey,
        taskName: this.formMyTask.formFilterCopy.taskName,
        startUser: this.formMyTask.formFilterCopy.startUser,
        keyOne: this.formMyTask.formFilterCopy.keyOne,
        ...params
      }
      return new Promise((resolve, reject) => {
        FlowOperationController.listRuntimeTask(params).then(res => {
          let list = res.data.dataList
          list.forEach(m => {
            Object.assign(m, m.formExtField)
          })
          resolve({
            dataList: list,
            totalCount: res.data.totalCount
          });
        }).catch(e => {
          reject(e);
        });
      });
    },
    loadTaskDataVerify () {
      this.formMyTask.formFilterCopy.processDefinitionName = this.formMyTask.formFilter.processDefinitionName;
      this.formMyTask.formFilterCopy.processDefinitionKey = this.formMyTask.formFilter.processDefinitionKey;
      this.formMyTask.formFilterCopy.taskName = this.formMyTask.formFilter.taskName;
      this.formMyTask.formFilterCopy.startUser = this.formMyTask.formFilter.startUser;
      this.formMyTask.formFilterCopy.keyOne = this.formMyTask.formFilter.keyOne;
      return true;
    },
    onSubmit (row) {
      let params = {
        processInstanceId: row.processInstanceId,
        processDefinitionId: row.processDefinitionId,
        taskId: row.taskId
      }

      FlowOperationController.viewRuntimeTaskInfo(params).then(res => {
        if (res.data) {
          this.$router.push({
            name: res.data.routerName || 'handlerFlowTask',
            query: {
              isRuntime: true,
              isDraft: row.isDraft,
              taskId: row.taskId,
              processDefinitionKey: row.processDefinitionKey,
              processInstanceId: row.processInstanceId,
              processDefinitionId: row.processDefinitionId,
              formId: res.data.formId,
              routerName: res.data.routerName,
              readOnly: res.data.readOnly,
              taskName: row.taskName,
              flowEntryName: row.processDefinitionName,
              processInstanceInitiator: row.showName,
              onStart: true,
              // 过滤掉加签、减签操作，加签、减签只有在已完成任务里可以操作
              operationList: (res.data.operationList || []).filter(item => {
                return item.type !== this.SysFlowTaskOperationType.CO_SIGN && item.type !== this.SysFlowTaskOperationType.REVOKE && item.type !== this.SysFlowTaskOperationType.SIGN_REDUCTION
              }),
              variableList: res.data.variableList
            }
          });
        }
      }).catch(e => {});
    },
    refreshMyTask (reloadData = false) {
      if (reloadData) {
        this.formMyTask.taskWidget.refreshTable(true, 1);
      } else {
        this.formMyTask.taskWidget.refreshTable();
      }
    },
    onResume () {
      this.refreshMyTask();
    },
    formInit () {
      this.refreshMyTask();
    }
  },
  mounted () {
    // 初始化页面数据
    this.formInit();
  }
}
</script>

<style>
</style>

import { render, staticRenderFns } from "./selectedProduct.vue?vue&type=template&id=119f415e&scoped=true"
import script from "./selectedProduct.vue?vue&type=script&lang=js"
export * from "./selectedProduct.vue?vue&type=script&lang=js"
import style0 from "./selectedProduct.vue?vue&type=style&index=0&id=119f415e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_css-loader@6.11.0_webpack@5.96.1__ejs@3.1.10_handlebars@_ucz5hgtaklppgz4ox5eoepgqae/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "119f415e",
  null
  
)

export default component.exports
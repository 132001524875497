import { render, staticRenderFns } from "./PropertiesPanel.vue?vue&type=template&id=6fd673d5"
import script from "./PropertiesPanel.vue?vue&type=script&lang=js"
export * from "./PropertiesPanel.vue?vue&type=script&lang=js"
import style0 from "./PropertiesPanel.vue?vue&type=style&index=0&id=6fd673d5&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_css-loader@6.11.0_webpack@5.96.1__ejs@3.1.10_handlebars@_ucz5hgtaklppgz4ox5eoepgqae/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <el-drawer title="产品详情信息" :visible.sync="show" direction="rtl" append-to-body size="800px" custom-class="demo-drawer"
    ref="drawer">
    <div class="p-20 out-wrapper">
      <div class="drawer__content">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-carousel indicator-position="outside">
              <el-carousel-item v-for="item in banners" :key="item">
                <el-image :src="item.url" class="slide-image"></el-image>
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <el-col :span="16">
            <div class="flex">
              <span class="title flex-1 m-r-20">{{ product.merchandiseName }}</span>
              <el-tag>{{ product.brandName }}</el-tag>
            </div>
            <div class="attribute">
              <span class="name">分销价</span>
              <span class="money">￥{{ Number(product.merSaleUnitPrice || 0).toFixed(2) }}</span>
            </div>
            <div class="attribute">
              <span class="name">类目</span>
              <span class=""></span>
            </div>
            <div class="attribute">
              <span class="name">供应商</span>
              <span class="">{{ product.supplierName }}</span>
            </div>            <div class="attribute">
              <span class="name">检测报告</span>
              <span class=""></span>
            </div>            <div class="attribute">
              <span class="name">3C报告</span>
              <span class=""></span>
            </div>
          </el-col>
        </el-row>
      </div>
      <div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="规格数据" name="first">
            <el-collapse v-model="collapse">
              <el-collapse-item v-for="item in attri" :key="item.title" :title="item.title" :name="item.title">
                <el-row :gutter="10">
                  <el-col :span="8" v-for="it in item.children" :key="it.pfcId">
                    {{ it.formatName }} : {{ it.formatValue}}
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </el-tab-pane>
          <el-tab-pane label="产品详情" name="second">
            <div v-for="(item, index) in details" :key="index">
              <el-image :src="item.url" style="width: 100%;" fit="fill">
              </el-image>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </el-drawer>
</template>

<script>

import { MmcMerchandiseTProductController as controller } from '@/api';
export default {
  data () {
    return {
      show: false,
      swiperOptionTop: {
        loop: true,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 6, // looped slides should be the same
        spaceBetween: 10,
        // centeredSlides: true,
        slidesPerView: 4,
        slideToClickedSlide: true
      },
      product: {},
      banners: [],
      activeName: 'first',
      attri: [], // 规格属性
      collapse: '商品属性',
      details: []
    }
  },
  methods: {
    activeCurImage (index) {
      // this.$refs.swiperTop.swiper.slideTo(index, 0)
    },
    open (value) {
      this.product = value
      this.show = true
      this.loadMerchandiseProduct(value.merchandiseId);
    },
    loadMerchandiseProduct (id) {
      const params = {
        merchandiseId: id
      }
      controller.productDetail(params).then(response => {
        const res = response.data

        this.banners = res.imageUrls
        this.attri = []
        const temp = res.mmcProductFormatValueGroupList
        for (const key in temp) {
          this.attri.push({
            title: key,
            children: temp[key]
          })
        }
        this.details = res.merDetailImageUrls
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.drawer__content {
  padding: 20px;
}

.gallery-top {
  .slide {
    .el-image {
      width: 200px;
      height: 200px;
    }

  }
}

.out-wrapper{
  overflow: scroll;
  height: 90vh;
}
.title{
  font-size: 20px;
  font-weight: bold;
}

.attribute{
  line-height: 2.5rem;
  .name{
    color: #999;
    // margin-right: 20px;
    width: 80px;
    display: inline-block;
  }
  .money{
    font-size: 18px;
    color: red;
  }
}
.gallery-thumbs{
  margin-top: 10px;
  .slide-image{
    width: 50px;
    height: 50px;
  }
}
</style>
